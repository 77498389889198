import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout';
import Seo from '@components/utility/SEO';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import { Link } from 'gatsby';
import './styles.scss';

const SiteMap = () => {
	return (
		<Layout>
			<Seo title="Site Map | EMFLAZA® (deflazacort)" description=" "/>
			<div className='relative min-h-screen page--sitemap'>
				<Hero
					heroImage='sitemap-hero-poly.png'
					heroImageMobile='sitemap-hero-poly-mobile.png'
					title='Site map'
					addedClass='blue-bg mt-5'
					useBreadcrumbs={false}
				/>
				<div className='sitemap-poly2 hidden sm:block left-0 absolute top-1/4  lg:min-h-[500px] z-negative'>
					<Image imageName='sitemap-poly2.png' loading="eager"></Image>
				</div>
				<div className='px-8 py-6 font-bold font-gotham lg:px-14 lg:text-2xl lg:py-12'>
					<ul>
						<li>
							<Link to='/'>
								Homepage
							</Link>
							<span className='sitemapArrowList'>
								<span className='arrow-right'></span>
							</span>
						</li>
						<li>
							<Link to='/unmet-need'>
								Unmet Need
							</Link>
							<span className='sitemapArrowList'>
								<span className='arrow-right'></span>
							</span>
						</li>
						<li>
							<h5>Efficacy</h5>
							<ul>
								<li>
									<Link to='/efficacy/muscle-function'>
										Muscle Function
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
								<li>
									<Link to='/efficacy/real-world-data'>
										Real-World Data
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
								<li>
									<Link to='/efficacy/long-term-outcomes'>
										Long-Term Outcomes
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
							</ul>
						</li>
						<li>
							<h5>Tolerability</h5>
							<ul>
								<li>
									<Link to='/tolerability/safety-profile'>
										Safety Profile
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
								<li>
									<Link to='/tolerability/weight-behavior'>
										Weight & Behavior
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
							</ul>
						</li>
						<li>
							<Link to='/dosing-administration'>
								Dosing & Administration
							</Link>
							<span className='sitemapArrowList'>
								<span className='arrow-right'></span>
							</span>
						</li>
						<li>
							<h5>Getting Started</h5>
							<ul>
								<li>
									<Link to='/getting-started/switch-considerations'>
										Switch Considerations
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
								<li>
									<Link to='/getting-started/prescription-start-form'>
										Prescription Start Form
									</Link>
									<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
									</span>
								</li>
							</ul>
						</li>
						<li>
							<Link to='/resources'>
								Resources
							</Link>
							<span className='sitemapArrowList'>
										<span className='arrow-right'></span>
							</span>
						</li>
					</ul>
					<div className='absolute right-0 hidden  sm:block -bottom-0'>
						<Image imageName='sitemap-poly3.png'></Image>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default SiteMap;
